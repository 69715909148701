/*Page 404 style*/

.error404 {
  background: url("../img/bg/404.jpg") center center / cover no-repeat fixed
    #333;
  text-align: center;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
}

/*Offline*/

.offline {
  background: #333;
}

/*.offline {
    background: url('../img/bg/offline.jpg') center center no-repeat fixed #E4E4E4;
    background-size: cover;
    color: #fff;
    text-align: center;
}*/

.offspinner {
  font-size: 6em;
  text-align: center;
}

.reconstruction {
  position: fixed;
  bottom: 0;
  font-size: 0.8em;
  background: #129608;
  opacity: 0.8;
  color: #fff;
  left: 50%;
  transform: translateX(-50%);
  padding: 2px 10px 0;
  text-align: center;
  z-index: 10000;
}
