.footer-welcome {
    font-family: "Montserrat-SemiBold", sans-serif;
    font-size: 1.2em;
    padding: 0 30px 30px;
    text-align: center;
    text-transform: uppercase;
}

.brands-slider {
    height: 300px;
    width: 80%;
    margin: 0 auto;
    margin-top: 50px;
}

.brands-slider figure {
    width: 200px;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.brands-slider figure a {
    display: block;
}


@media screen and (min-width:768px) {

    .brands-slider .uk-slidenav.uk-slidenav-previous {
        left: -50px;
    }

    .brands-slider .uk-slidenav.uk-slidenav-next {
        right: -50px;
    }
}

/*Map*/

.el-footer-map {
    position: relative;
    height: 50vh;
    width: 100%;
}

#map {
    width: 100%;
    height: 100%;
}

#router {
    box-sizing: border-box;
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    padding: 10px;
    background-color: red;
    cursor: pointer;
    bottom: 15px;
    left: 50%;
    transform: translate(-50%);
    box-shadow: 3px 3px 5px rgba(0, 0, 0, .3);
}

.routing-load {
    animation: routing 1s linear infinite;
}

#router svg {
    fill: #fff;
    width: 90%;
}


@keyframes routing {
    50% {
        background-color: #fff;
    }
}

/*Green ribbon*/

.el-ribbon {
    background: #129608;
    height: 3px;
    width: 100%;
}


/*Footer main container*/

.el-fcontacts {
    position: relative;
    margin: 0 auto;
    padding: 25px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 65%;
}


/*Logo footer*/

.el-footer-logo p {
    font-size: 1em;
    color: #888;
    text-align: center;
}

.logo-footer {
    box-sizing: border-box;
    height: auto;
    width: 65%;
}


/*Menu footer*/

.el-fm {
    display: flex;
    justify-content: center;
}

.el-fm ul {
    padding: 0
}

.el-footer-menu {
    list-style: none;
    font-size: 1.1em;
    line-height: 2;
}

.el-footer-menu a {
    color: #000;
}

.el-footer-menu a:hover {
    color: #129608;
    text-decoration: none;
}


/*Contacts footer*/

.el-footer-contacts {
    font-size: 1.1em;
}

.footer-tels a {
    display: block;
}

.el-fc i {
    font-size: 1.5em;
    color: #129608;
}

.el-fc p,
.el-footer-social p,
.el-fc a {
    color: #000;
    font-size: 1em;
}

.el-fc a:hover {
    text-decoration: none;
    color: #129608;
}


/*Social footer*/

.el-footer-social {
    /*font-size: 1.1em;*/
    line-height: normal;
    color: #000;
    text-align: center;
}

.footer-social {
    text-align: center;
    display: inline-block;
    height: 35px;
    margin: 2px;
    border: 1.2px solid #888;
    border-radius: 50%;
    transition: all ease .3s;
    width: 35px;
}

.footer-social:hover {
    background: #CBCBCB;
    border: 1.2px solid #CBCBCB;
    cursor: pointer;
    outline: 0;
    color: #fff;
}

.footer-social i {
    font-size: 1.2em;
    color: #129608;
    margin: 0 auto;
    position: relative;
    top: 25%;
}


/*Paddings and container width on small screen*/

@media screen and (max-width:768px) {
    .el-fcontacts>div {
        padding-top: 10px;
        padding-bottom: 10px;
    }

    .el-fcontacts {
        max-width: 90%;
    }
}