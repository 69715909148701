/*Breadcrumbs style*/

.def-breadcrumbs .uk-active {
    font-weight: 900;
}

.def-breadcrumbs a {
    color: #129608;
    font-weight: 700;
    text-decoration: none;
}

.def-breadcrumbs a:hover {
    color: #31B427;
}

.front-breadcrumb {
    position: absolute;
    padding: 15px 8px 0 30px;
    top: 15%;
    left: 0;
    z-index: 99;
    background: #fff;
    background: rgba(255, 255, 255, .8);
}
