/*Homepage 1 screen*/


.welcome {
    min-height: 100vh;
    max-height: auto;
}

.homepage-welcome {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%);
}

.welcome-title h1 {
    color: #fff;
    background: rgba(18, 150, 8, .9);
    padding: 5px 10px 10px;
    display: inline;
    font-family: "RobotoSlab-Regular", serif;
}

.welcome-subtitle * {
    font-family: "Montserrat-SemiBold", sans-serif;
    color: #222;
    font-size: 1.2em;
    margin-bottom: 50px;
    margin-top: 30px;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .homepage-welcome {
        background-size: auto 100%;
    }

    .welcome-title {
        background: rgba(18, 150, 8, .9);
    }

    .welcome-title h1 {
        background: transparent;
        font-size: 2em;
    }

    .welcome-subtitle * {
        font-size: 1em;
    }
}

/*Homepage catalog*/

.homepage-catalog {
    background: #f5f5f5;
    margin: 0 auto;
    text-align: center;
    padding: 50px 0;
}

.cat-thumb {
    box-sizing: border-box;
    border-bottom: 3px solid transparent;
    background: #fff;
    display: inline-block;
    min-width: 100%;
    max-width: 100%;
    margin: 0;
    transition: all 0.3s ease;

    img {
        min-width: 100%;
        min-height: 100%;
    }
}

// .cat-thumb>img,
// .cat-thumb>picture {
//     min-width: 100%;
//     min-height: 100%;
// }

.cat-thumb:hover {
    border-bottom: 3px solid #129608;
    text-decoration: none;
}

@media screen and (orientation:portrait) {
    .cat-thumb {
        border-bottom: 3px solid #129608;
    }
}

.cat-thumb-cap {
    font-size: 0.6em;
    padding-top: 10px;
    height: 45px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.cat-thumb-cap>* {
    font-size: 1.8em;
    line-height: normal;
    font-weight: 700;
    text-transform: uppercase;
}

/*Homepage 2 screen*/

.about-us {
    padding: 100px 0;
    margin: 0 auto;
}

.about-us-panel {
    box-sizing: border-box;
    border: 3px solid transparent;
    padding: 15px;
}

.about-us-panel-img {
    padding: 10px;
}

.about-us-panel-txt {
    padding: 15px;
}

@media (max-width: 768px) {
    .about-us-panel-img {
        width: 50%;
        margin: 0 auto;
    }

    .about-us-panel-txt {
        text-align: center;
        margin: 0 auto;
    }
}

/*3 Homepage Screen*/

.advantages {
    background: #ddd;
    text-align: center;
    padding: 100px 0 0;
    margin: 0;
}

.adv-text-block {
    background: #fff;
    background: rgba(255, 255, 255, .9);
    box-sizing: border-box;
    position: absolute;
    padding: 30px;
    width: 30%;
}

.adv-text-left {
    border-left: 3px solid #129608;
    left: 15%;
    z-index: 10;
}

.adv-text-right {
    border-right: 3px solid #129608;
    right: 15%;
    z-index: 10;
}

@media (max-width: 768px) {
    .adv-text-block {
        position: relative;
        width: 100%;
    }

    .adv-text-left,
    .adv-text-right {
        border: none;
        border-top: 3px solid #129608;
        left: 0;
        right: 0;
        z-index: 1;
    }
}

/*Order*/

.order {
    text-align: center;
    padding: 100px 0 0;
    margin: 0 auto;
}

.order-icon img {
    width: 25%;
    margin: 10px 30px 5px;
}