/*Comments style*/

.def-comments {
	margin: 30px;
	padding-bottom: 100px;
}

.front-comments {
    font-size: 1.5em;
    padding: 35px 35px 50px;
}

