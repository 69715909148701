/*Animated mouse scroll*/

.mouse {
    max-width: 1em;
    width: 100%;
}
.scroll {
    animation-name: scroll;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.65, -0.55, 0.25, 1.5);
    animation-iteration-count: infinite;
}
.scroll-link {
    bottom: 2em;
    font-size: 1.8em;
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
}

@keyframes scroll {
    0%, 20% {
        transform: translateY(0) scaleY(1);
    }
    10% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: translateY(36px) scaleY(2);
    }
}