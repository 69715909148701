.mob-nav-margin {
    margin-bottom: 10vh;
}

.mob-nav-top {
    background: #fff;
    display: flex;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 3;
    box-shadow: 2px 2px 5px rgba(0,0,0,.2);
}

.mob-nav-left {
    align-items: center;
    background: #333;
    color: #fff;
    display: flex;
    font-size: 1.8em;
    justify-content: center;
    width: 20%;
}

.mob-nav-left:hover,
.mob-nav-left:focus {
    color: #129608;
    text-decoration: none;
}

.mob-nav-center {
    align-items: center;
    justify-content: center;
    display: flex;
    width: 60%;
}

.mob-nav-center img {
    height: 8vh;
    margin: 5px;
}

.mob-nav-right {
    background: #333;
    width: 20%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.mob-lang-switch {
    color: #fff;
    font-weight: bold;
    font-size: 1.5em;
    text-transform: uppercase;
}

.mob-lang-switch:hover,
.mob-lang-switch:focus {
    color: #129608;
    text-decoration: none;
    transition: all .3s ease;
}

.mob-lang-select {
    background-color: #333;
    margin-top: 0;
    text-transform: uppercase;
    width: 100%;
}

.mob-lang-select a {
    color: #fff;
    display: block;
    font-size: 1.5em;
    font-weight: 700;
    padding: 12px;
    text-align: center;
    text-decoration: none;
		outline: none;
}

.mob-lang-select a:hover {
    background: #ddd;
    color: #331a00;
}


/*Mobile modal*/

.mob-menu-modal {
    background: rgba(0, 0, 0, 0.6);
    border: none;
    border-radius: 0;
    height: 100vh;
}

.mob-modal-top {
    align-items: center;
    display: flex;
    height: 10vh;
    justify-content: center;
}

.mob-modal-close {
    color: #E1E1E1!important;
    opacity: 1;
    font-size: 2.2em;
}


/*Mobile modal Center Mob Menu*/

.mob-modal-center {
    position: absolute;
    top: 10vh;
    align-items: center;
    display: flex;
    height: 80vh;
    justify-content: center;
    overflow-y: scroll;
    width: 100vw;
}

.mob-menu {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
}

.mob-menu a {
    color: #818181;
    display: block;
    font-size: 1.5em;
    padding: 10px 0;
    margin: 8px 0;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.3s;
    font-weight: 700;
}

.mob-menu a:hover,
.mob-menu a:focus {
    color: #fff;
}

.mob-modal-bottom {
    bottom: 0;
    color: #129608;
    display: flex;
    align-items: center;
    height: 10vh;
    position: absolute;
    width: 100vw;
}

.mob-phone,
.mob-mail {
    align-items: center;
    background: #129608;
    display: flex;
    height: 10vh;
    justify-content: center;
}

.mob-phone i,
.mob-mail i {
    color: #fff;
    font-size: 2.3em;
}

.mob-phone-select {
    background: #129608;
    margin: 0;
    color: #fff;
    width: 100vw;
}

.mob-phone-select li>a {
    font-weight: 500;
    color: #fff;
    display: block;
    font-size: 1.5em;
    padding: 8px;
    text-align: center;
}

.mob-phone-select li>a:hover {
    background: #ddd;
    color: #331a00;
}

.mob-social {
    align-items: center;
    background: #000;
    bottom: 0;
    display: flex;
    height: 10vh;
    justify-content: center;
}

.mob-social i {
    color: #129608;
    font-size: 2em;
    letter-spacing: 5px;
}