@font-face {
  font-family: "Montserrat-Regular";
  src: local("Montserrat-Regular"),
    url("../fonts/Montserrat-Regular.woff2") format("woff2");
  // font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Montserrat-SemiBold";
  src: local("Montserrat-SemiBold"),
    url("../fonts/Montserrat-SemiBold.woff2") format("woff2");
  // font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
    font-family: "RobotoSlab-Regular";
    src: local("RobotoSlab-Regular"),
      url("../fonts/RobotoSlab-Regular.woff2") format("woff2");
    // font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

html {
  font-family: "Montserrat-Regular", sans-serif;
}

h1 {
  font-family: "Montserrat-SemiBold", sans-serif;
  /*font-size: 2.8em;*/
  text-transform: uppercase;
}

h2 {
  color: #222;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 2em;
  text-transform: uppercase;
}

h3 {
  color: #222;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 1.8em;
  text-transform: uppercase;
  line-height: normal;
}

h4 {
  color: #222;
  font-family: "Montserrat-SemiBold", sans-serif;
  font-size: 1.2em;
  text-transform: uppercase;
}

h5 {
  color: #222;
  font-family: "Montserrat-SemiBold", sans-serif;
  text-transform: uppercase;
}

p {
  font-family: "Montserrat-Regular", sans-serif;
  font-size: 1em;
  line-height: normal;
}

/*@media screen and (max-width: 480px) {
    h1 {
        font-size: 2.5em;
    }
    h2 {
        font-size: 2em;
    }
    h3 {
        font-size: 1.5em;
    }*/
/*}*/
