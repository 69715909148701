.product-slideshow {

  .uk-slidenav-position {
    height: 100vh;
    ul, li {
      height: 100vh;
    }

    img, picture {
      height: 100%;
      object-fit: cover;
    }
  }
}

.single-thumb {

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 2px solid transparent;
    transition: border-color .3s ease
  }

  &:hover {
    img {
      border-color: green;
    }
  }

}