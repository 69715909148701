/*Catalog menu*/

.left-sidebar {
    background: #BCB588;
}

.catalog-menu {
    padding: 20px 0 100px 0;
    text-align: right;
}

.catalog-menu .uk-parent>a {
    padding: 10px;
    background: linear-gradient(to right, #BCB588, #968E59);
    color: #331A00;
    font-weight: 700;
    text-transform: uppercase;
}

.catalog-menu ul {
    padding-right: 20px;
}

.catalog-menu .uk-parent>a:hover {
    font-size: 1.1em;
}

.catalog-menu .uk-parent>a.active {
    background: linear-gradient(to right, #BCB588, #F5F5F5);
    font-size: 1.1em;
}

.catalog-menu .uk-nav-sub a {
    color: #585227;
}

.catalog-menu .uk-nav-sub a:hover,
.catalog-menu .uk-nav-sub a.active {
    color: #331A00;
    font-size: 1.2em;
}

.catalog-menu .uk-nav-sub>li>a.active {
    color: #fff;
    text-shadow: 0 0 3px #120B03;
}