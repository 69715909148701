.fronty-thumb {
  height: 250px;
  width: 250px;
  // height: 13vw;
  // width: 13vw;
  margin-right: auto;
  margin-left: auto;
  // height: 100%;


  @media (min-width: 480px) {
    height: 200px;
    width: 200px;
  }

  @media (min-width: 680px) {
    height: 230px;
    width: 230px;
  }


  @media (min-width: 960px) {
    height: 12vw;
    width: 12vw;
  }

  @media (min-width: 1220px) {
    height: 13vw;
    width: 13vw;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border: 2px solid transparent;
    transition: border-color .3s ease
  }

  &:hover {
    img {
      border-color: green;
    }
  }
}