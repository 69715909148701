body {
  overflow-x: hidden;
}

.nohover {
  pointer-events: none;
}

/*Main blocks*/

.content {
  min-height: 100vh;
  padding-top: 75px;
  margin: 0 auto;
}

.main-container {
  position: relative;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.product-preview__wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.product-preview {
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    padding-top: 0!important;
    // padding-left: 0!important;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.text-block {
  padding: 35px;
}

.hblock-title {
  text-align: center;
}

.hblock-subtitle {
  padding: 20px;
}

.order-panel p {
  padding: 0 20px;
}

.product-price {
  background: #129608;
  padding: 10px 15px;
  color: #fff;
  font-size: 1.3em;
  line-height: normal;
  margin-bottom: 15px;
}

.price-in-cat {
  position: absolute;
  top: 10px;
  right: 0;
  background: #129608;
  padding: 5px 10px;
  color: #fff;
  text-align: center;
  z-index: 999;
}

/*Fronts*/

.mf-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 4px solid #129608;
  min-height: 150px;
  font-size: 1.5em;
  color: #129608;
  transition: all 0.5s ease;
  text-align: center;
}

.mf-icon:hover {
  text-decoration: none;
  color: #fff;
  background: #129608;
  font-weight: 700;
}

.fronts-container {
  padding: 120px 35px;
  margin: 0 auto;
}

.fronts-hr {
  border-bottom: 2px solid #129608;
  margin-bottom: 15px;
}

/*Articles*/
.article-category {
  margin: 0 auto 100px;
  padding: 15px;
}

@media screen and (min-width: 768px) {
  .article-category > div {
    padding: 0;
  }
}

.article-category > div {
  /*padding: 15px;*/
}

.article-category-title {
  margin: 100px auto 50px;
}

.front-article-image img {
  min-height: 100vh;
  min-width: 100vw;
}

.front-article-title {
  background: #999;
  background: rgba(0, 0, 0, 0.2);
  text-align: center;
  text-shadow: 3px 2px 5px rgba(0, 0, 0, 0.5);
}

.front-article {
  padding: 5% 10%;
}

/*Extra styles*/

a {
  transition: all ease 0.3s;
}

.uk-button {
  text-transform: uppercase;
}

.uk-button-success {
  background: #129608;
}

.uk-text-success {
  color: #129608;
}

.uk-button-success:hover {
  background: #31b427;
}

.hidden {
  display: none;
}

.ico-color {
  color: #129608;
}

.gotop {
  bottom: 40px;
  color: #129608;
  cursor: pointer;
  display: none;
  font-size: 2.2em;
  position: fixed;
  left: 1em;
  z-index: 1000;
}

.gotop:hover {
  color: #fff;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.9);
}

.lang-switcher {
  list-style-type: none;
  margin: 0;
}

.lang-switcher li {
  float: left;
  padding-left: 3px;
}

.lang-switcher a {
  color: #222;
  text-decoration: none;
  text-transform: uppercase;
}

.lang-switcher .active {
  color: #129608;
  font-weight: 900;
}

.front-shadow {
  text-shadow: 3px 2px 5px rgba(0, 0, 0, 0.5);
}

.upload-holder {
  border: dashed 3px #d8d8d8;
  margin: 20px;
  padding: 20px;
}

.orange-button {
  background: #f6961d;
  border-radius: 5px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  padding: 3px 30px;
  color: #fff;
  font-size: 1.1em;
  text-transform: uppercase;
  text-align: center;
}

.green-hr {
  width: 150px;
  border-bottom: 3px solid #129608;
  margin: 0 auto;
  margin-bottom: 50px;
}

.calculate-form {
  font-size: 1.2em;
  line-height: normal;
}
.calculate-form label {
  font-weight: bolder;
  margin-right: 20px;
  padding-bottom: 10px;
  cursor: pointer;
  display: flex;
  outline: none;
  outline-style: hidden;
}

.calculate-form label > span {
  margin-left: 5px;
  font-weight: normal;
}

.calculate-form hr {
  margin-top: 30px;
}
