.about-intro-bg {
    min-height: 50vh;
    margin-top: 50px;
    background: url('../img/bg/aboutus-bg.jpg') center center / cover no-repeat;
}

.about-intro-text {
    background: rgba(255, 255, 255, .9);
    margin: 0 auto;
    margin-top: -25vh;
    margin-bottom: 30px;
    padding: 30px 60px;
    z-index: 999;
}


.aims {
    margin: 0 auto;
    text-align: center;
    padding: 15px;
}

.aims img {
    width: 30%;
    margin: 10px 30px 5px;
}

.aboutus-fin {
    margin: 100px auto;
    border: 3px solid #F6961D;
    text-align: center;
    padding: 35px;
}

.about-adv {
    margin: 100px auto;
    padding: 15px;
}

.about-adv2 {
    padding: 100px 0;
    background: #f5f5f5;
}

.adv2-grid{
    position: relative;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 15px;

}

.icon-cont {
    text-align: center;
    margin-bottom: 15px;
}

.el-icon {
    width: 25%;
    height: auto;
}

.text {
    font-size: 1em;
    line-height: 1.5;
		text-align: center;
		padding: 15px 0;
    margin: 0;
}

@media screen and (min-width: 768px) {
		.text {
			text-align: left;
    	padding: 15px;
		}

		.adv2-grid{
		   padding: 0;
		}
}