
// Normalize
@import "modern-normalize/modern-normalize.css";

@import 'vendor/uikit';
@import 'vendor/compile/accordion';
@import 'vendor/compile/dotnav';
@import 'vendor/compile/notify';
@import 'vendor/compile/slidenav';
@import 'vendor/compile/slider';
@import 'vendor/compile/slideshow';
@import 'vendor/compile/sticky';

@import 'components/text';
@import 'components/header';
@import 'components/footer';
@import 'components/template';
@import 'components/system';
@import 'components/breadcrumbs';
@import 'components/comments';
@import 'components/menu_sidebar';
@import 'components/menu_mobile';
@import 'components/mouse';
@import 'components/category';
@import 'components/product';

@import 'pages/homepage';
@import 'pages/aboutus';


.lazy {
  transition: opacity .3s ease;
}
.loaded {
  opacity: 1 !important;
  visibility: visible !important;
}